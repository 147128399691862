import React, { useState } from 'react';
import VideoBack from 'components/VideoBack';
import QuestionBox from 'components/QuestionBox';
import HomeButton from 'components/Buttons/HomeButton';
import QuestionButton from 'components/Buttons/QuestionButton';
import {PageWrapperBox,SeminisLogo,BayerBox} from './styles';

const LegalesBayer = ({isHome}) =>{

    return (<BayerBox>
        <div className="legales">
            <img className="blogo" src="/assets/images/bayer.png" alt="Bayer" />
            <div className="text">
                <p>Bayer, Bayer Cross Design and Seminis® are registered trademarks of Bayer Group. </p>
                
                <p>©2004-2020 Bayer Group. All rights reserved.</p>
            </div>
        </div>
        {isHome && <p className="thanks">Thank you to Gelattina and Andover Media for your collaboration on this project.</p>}
    </BayerBox>
    )
}

const PageWrapper = ({children,backGround,video,center,hmbtn,padding,isHome}) =>{
    const [qstBox,setQstBox] = useState({open:false});

    return (<PageWrapperBox backGround={backGround} center={center} padding={padding}>
        <SeminisLogo src="/assets/images/seminis_small.png" alt="Seminis" />
        {children}
        <LegalesBayer isHome={isHome} />
        {video && <VideoBack video={video} loop={true} /> }
        {hmbtn && <HomeButton to="/fields" />}
        <QuestionButton onClick={()=>setQstBox({open:true})} />
        {qstBox.open && <QuestionBox onClose={()=>setQstBox({open:false})} />}
    </PageWrapperBox>)
}

export default PageWrapper;