import React from 'react';
import {LoadingBox,DialogBox,SeminisLogo} from './styles';

const LoadingModal = () =>{

    return (<LoadingBox>
        <DialogBox>
            <SeminisLogo src="/assets/images/seminis_big.png" alt="Seminis" />
        </DialogBox>
    </LoadingBox>)
}

export default LoadingModal;