

export const pagesData = {
    fields: { path: '/fields', exact: true },
    garden_bean: { title:"Garden bean", path:'/crops/garden-bean' },
    onion: { title:"Onion", path:'/crops/onion' },
    cucumber: { title:"Cucumber", path:'/crops/cucumber' },
    green_pepper: { title:"Green pepper", path:'/crops/green-pepper' },
    sweet_corn: { title:"Sweet Corn", path:'/crops/sweet-corn' },
    watermelon: { title:"Watermelon", path:'/crops/watermelon' },
    specialty_pepper: { title:"Specialty pepper", path:'/crops/specialty-pepper' },
    tomato: { title:"Tomato", path:'/crops/tomato' }
}