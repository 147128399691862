import React, { useRef } from 'react';
import {VideoTag} from './styles';



const VideoBack = ({video,onVideoEvent,loop,accelerate}) =>{
    const vidRef= useRef(null);
    const videoURL = `/assets/videos/${video}`;
    let videoOpts = {autoPlay:true,loop,muted:true,playsInline:true};

    if(onVideoEvent){
        videoOpts.onCanPlay= ()=> onVideoEvent({play:true});
        videoOpts.onEnded= ()=> {
            vidRef.current.playbackRate = 1.0;
            onVideoEvent({end:true});
        };
        videoOpts.onError= ()=> onVideoEvent({error:true});
    }
    if(accelerate){
        videoOpts.onClick = () =>{
            vidRef.current.playbackRate = 5.0;
        }
    }

    return (<VideoTag ref={vidRef} {...videoOpts} key={video}>
        <source src={`${videoURL}.mp4`} type="video/mp4" />
    </VideoTag>)
}

export default VideoBack;