import styled from 'styled-components';

export const CloseButtonDiv = styled.div`
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    & svg{
        color: #fff;
        width: 35px;
        height: 35px;
    }
`;
