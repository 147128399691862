import styled from 'styled-components';

export const QuestionButtonDiv = styled.div`
    position: absolute;
    z-index: 8;
    
    & svg{
        color: #fff;
        width: 35px;
        height: 35px;
    }
    @media (max-width: 768px) {
        top: 40px;
        right: 60px;
    }
    @media (min-width: 768px) {
        bottom: 40px;
        right: 75px;
    }
`;
