import styled from 'styled-components';

const centerContent = (center) =>{
    if(center){
        return `
            display: flex;
            align-items: center;
            justify-content: center;
        `
    }
    return null;
}

const addPadding = (padding) =>{
    if(padding){
        return `
        padding-top: 90px;
        padding-bottom: 90px;
        `
    }
    return null;
}

export const PageWrapperBox = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-image: url(/assets/images/backgrounds/${props=> props.backGround}.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  ${props=> addPadding(props.padding)}
  ${props=> centerContent(props.center)}
`

export const SeminisLogo = styled.img`
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;
    @media (min-width: 768px) {
        top: 40px;
        left: 50px;
    }
`

export const BayerBox = styled.div`
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 8;
    & .legales{
        display: flex;
        align-items: center;
        & .blogo{
            width: 40px;
        }
        & .text{
            color: #FFF;
            text-shadow: 1px 1px 2px black;
            margin-left: 1rem;
            font-family: 'open_sans_regular';
            & p{
                padding: 0;
                margin: 0;
                font-size: .6rem;
            }
        }
    }
    & .thanks{
        margin-bottom: 0;
        text-shadow: 1px 1px 2px black;
        margin-left: 55px;
        color: #fff;
        font-size: .6rem;
    }
    
    @media (min-width: 768px) {
        bottom: 20px;
        left: 50px;
        & .legales{
            & .blogo{
                width: 51px;
            }
            & .text{
                & p{
                    font-size: .8rem;
                }
            }
        }
    }
`