import React from 'react';
import PageWrapper from 'components/PageWeapper';
import {WelcomeBox,TextBox,ButtonEnter} from './styles';

const HomePage = () =>{

    return (<PageWrapper backGround="field" padding={false} center={false} isHome={false}>
       <WelcomeBox>
           <TextBox>
               <h1>WELCOME TO SEMINIS SKY VIEW</h1>
               <p>Seminis brings you whole new way to experience our crops, to walk our fields and listen to what our experts want to share with you, virtually!</p>
               <p>What you are seeing is actual footage from our field in Hall, New York, shot by aerial cameras. Just tap/click and choose one of our eight crops and the Seminis Crop-Drone will follow your instructions and give you a closer look at the new varieties and their advantages over other seeds.</p>
               <p>Feel free to explore and get ready to discover what Seminis has brought you to enjoy without leaving home or your workspace.</p>
               <ButtonEnter to="/fields">ENTER</ButtonEnter>
           </TextBox>
       </WelcomeBox>
    </PageWrapper>)
}

export default HomePage;