import styled from 'styled-components';

export const LoadingBox = styled.div`
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1071;
`;

export const DialogBox = styled.div`
    margin: auto;
`
export const SeminisLogo = styled.img`
    width: 100%;
    max-width: 450px;
    padding: 1rem;
    box-sizing: border-box;
`