import styled from 'styled-components';

export const QuestionBoxTag = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
`

export const QuestionFormTag = styled.div`
    padding: 1rem;
    border-radius: 1.5rem;
    background-color: #36873e;
    margin: 1rem;
    z-index: 10;
    & .title{
        font-family: 'haboro_contrastext_bold';
        font-size: 1.5rem;
        color: #fff;
    }
    & .form-group{
        margin-bottom: 1rem;
        & .form-label{
            font-family: 'haboro_contrastext_bold';
            display: inline-block;
            margin-bottom: .2rem;
            margin-left: .2rem;
            color: #fff;
            font-weight: 600;
        };
        & textarea{
            height: 60px!important;
        }
        & .form-control{
            display: block;
            width: 100%;
            height: calc(1.5em + .75rem + 2px);
            padding: .375rem .75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        
    }
    & .btn{
        font-family: 'haboro_contrastext_bold';
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        transition: color .15s;
        width: 100%;
        padding: .5rem 1rem;
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        cursor: pointer;
    }
`