import styled from 'styled-components';

export const VideoTag = styled.video`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    object-fit: cover;
    transform: translate(-50%,-50%);
    z-index: 0;
`