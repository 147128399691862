import styled from 'styled-components';
import {Link} from "react-router-dom";

export const HomeButtonDiv = styled(Link)`
    position: absolute;
    z-index: 8;
    
    & svg{
        color: #fff;
        width: 35px;
        height: 35px;
    }
    @media (max-width: 768px) {
        top: 40px;
        right: 20px;
    }
    @media (min-width: 768px) {
        bottom: 40px;
        right: 30px;
    }
`;
