import React,{Suspense,lazy} from 'react';
import ReactDOM from 'react-dom'; 
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { pagesData } from './constants';
import GlobalStyle from './styles';
import HomePage from 'pages/Home';
import LoadingModal from 'components/LoadingModal';

const FieldsPage = lazy(() => import('pages/Fields'));
const CropsPage = lazy(() => import('pages/Crops'));

const SeminisWeb = () =>{
  return (<React.StrictMode>
      <GlobalStyle />
      <BrowserRouter>
          <Suspense fallback={<LoadingModal />}>
            <Switch>
              <Route path={["/crops/:crop","/crops/:crop/:type"]} exact={true} component={CropsPage}/>
              <Route {...pagesData.fields} component={FieldsPage}/>
              <Route component={HomePage}/>
            </Switch>
          </Suspense>
      </BrowserRouter>
  </React.StrictMode>)
}

ReactDOM.render(<SeminisWeb />,document.getElementById('root'));
