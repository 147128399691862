import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    *{box-sizing: border-box;}
    body {
        background: #000;
        margin: 0;
        padding: 0;
    }
    @font-face {
        font-family: 'open_sans_bold';
        src: url('/assets/fonts/opensans-bold.woff2') format('woff2'),
            url('/assets/fonts/opensans-bold.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'open_sans_regular';
        src: url('/assets/fonts/opensans-regular.woff2') format('woff2'),
            url('/assets/fonts/opensans-regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'haboro_contrastext_bold';
        src: url('/assets/fonts/haboro_contrast_ext_bold.woff2') format('woff2'),
             url('/assets/fonts/haboro_contrast_ext_bold.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'haboro_contrastext_medium';
        src: url('/assets/fonts/haboro_contrast_ext_medium.woff2') format('woff2'),
             url('/assets/fonts/haboro_contrast_ext_medium.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'haboro_contrastext_regular';
        src: url('/assets/fonts/haboro_contrast_ext_regular.woff2') format('woff2'),
             url('/assets/fonts/haboro_contrast_ext_regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
`

export default GlobalStyle;