import styled from 'styled-components';
import {Link} from "react-router-dom";

export const WelcomeBox = styled.div`
   width: 100%;
   height: 100vh;
   background-color: #36873e;
   @media (min-width: 768px) {
       max-width: 700px;
   }
`

export const TextBox = styled.div`
    overflow: hidden;
    padding-top: 8rem;
    padding-left: 3rem;
    padding-right: 3rem;
    color: #FFF;
    & h1{
        font-family: 'haboro_contrastext_bold';
        text-transform: uppercase;
        font-size: 2rem;
        padding: 0;
        margin-top: 0;
    }
    & p{
        font-family: 'open_sans_regular';
        font-size: .8rem;
    }

    @media (min-width: 768px) {
        padding-top: 10rem;
        padding-left: 10rem;
        padding-right: 3rem;
        & h1{
            font-size: 3rem;
        }
        & p{
            font-size: 1rem;
        }
    }
    @media (max-height: 768px) {
        & h1{
            font-size: 1rem;
        }
        & p{
            font-size: .8rem;
        }
    }
`

export const ButtonEnter = styled(Link)`
    font-family: 'haboro_contrastext_bold';
    background: none;
    display: inline-block;
    border: 1px solid #FFF;
    padding: .5rem 1rem;
    border-radius: 20px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    &:hover{
        background: rgba(255,255,255,.2);
    }
`