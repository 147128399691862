import React, { useState } from 'react';
import fetchData from 'components/Api';
import CloseButton from 'components/Buttons/CloseButton';
import {QuestionBoxTag,QuestionFormTag} from './styles';

const QuestionBox = ({onClose}) =>{
    const [question,setQuestion] = useState({email:"",question:""});

    const onDataChange = ({currentTarget}) =>{
        setQuestion({...question,[currentTarget.name]:currentTarget.value})
    }

    const onSubmit = () =>{
        if(question.email.length>0){
            
            fetchData.post('data/questions.php',question).then((rsp)=>{
                console.log(rsp)
                const {error,success} = rsp;
                if(error){
                    window.alert(`ERROR!! ${error}`);
                }
                if(success){
                    onClose();
                }
            })
        }
    }

    return (<QuestionBoxTag>
        <CloseButton onClick={onClose} />
        <QuestionFormTag>
            <h2 className="title">Let us know if you have any questions!</h2>
            <div className="form-group">
                <label className="form-label">Email</label>
                <input type="text" name="email" className="form-control" onChange={(e)=>onDataChange(e)} />
            </div>
            <div className="form-group">
                <label className="form-label">Question</label>
                <textarea className="form-control" name="question" rows="3" onChange={(e)=>onDataChange(e)}></textarea>
            </div>
            <button type="submit" className="btn btn-primary" onClick={onSubmit}>Submit</button>
        </QuestionFormTag>
    </QuestionBoxTag>)
}

export default QuestionBox;